const options = {
  categories: [
    { value: 'news', text: 'News' },
    { value: 'media', text: 'Media' },
    { value: 'automoto', text: 'Auto / Moto' },
    { value: 'administration', text: 'Administration' },
    { value: 'banks', text: 'Banks' },
    { value: 'freeonline', text: 'Free online' },
    { value: 'business', text: 'Business Finance' },
    { value: 'children', text: 'Children' },
    { value: 'design', text: 'Design' },
    { value: 'womanclub', text: 'Woman Club' },
    { value: 'laws', text: 'Laws' },
    { value: 'dating', text: 'Dating' },
    { value: 'games', text: 'Games' },
    { value: 'onlinestores', text: 'Online stores' },
    { value: 'art', text: 'Art' },
    { value: 'history', text: 'History' },
    { value: 'cinema', text: 'Cinema' },
    { value: 'company', text: 'Company Profile' },
    { value: 'computers', text: 'Computers' },
    { value: 'literature', text: 'Literature' },
    { value: 'medicine', text: 'Medicine' },
    { value: 'menclub', text: 'Men\'s club' },
    { value: 'music', text: 'Music and mp3' },
    { value: 'science', text: 'Science and education' },
    { value: 'realestate', text: 'Real Estate' },
    { value: 'recreation', text: 'Recreation' },
    { value: 'weather', text: 'Weather' },
    { value: 'politics', text: 'Politics' },
    { value: 'group', text: 'Group' },
    { value: 'portals', text: 'Portals' },
    { value: 'providers', text: 'Providers' },
    { value: 'programs', text: 'Programs' },
    { value: 'food', text: 'Food' },
    { value: 'industry', text: 'Industry' },
    { value: 'travels', text: 'Travels' },
    { value: 'job', text: 'Job' },
    { value: 'radio', text: 'Radio' },
    { value: 'entertainment', text: 'Entertainment' },
    { value: 'adult', text: 'Adult Entertainment' },
    { value: 'regionalsites', text: 'Regional sites' },
    { value: 'advertising', text: 'Advertising' },
    { value: 'religion', text: 'Religion' },
    { value: 'communication', text: 'Communication' },
    { value: 'services', text: 'Services' },
    { value: 'sport', text: 'Sport' },
    { value: 'building', text: 'Building' },
    { value: 'tv', text: 'TV' },
    { value: 'goods', text: 'Goods and services' },
    { value: 'transfer', text: 'Transfer' },
    { value: 'hobbies', text: 'Hobbies' },
    { value: 'photography', text: 'Photography' },
    { value: 'chats', text: 'Chats and Forums' },
    { value: 'chumorhats', text: 'Humor' },
    { value: 'other', text: 'Other' },
  ],
  countries: [
    { value: 'ukraine', text: 'Ukraine' },
    { value: 'israel', text: 'Israel' },
    { value: 'russia', text: 'Russia' },
    { value: 'uzbekistan', text: 'Uzbekistan' },
    { value: 'usa', text: 'USA' },
    { value: 'belarus', text: 'Belarus' },
    { value: 'germany', text: 'Germany' },
    { value: 'brazil', text: 'Brazil' },
    { value: 'poland', text: 'Poland' },
    { value: 'czechia', text: 'Czechia' },
    { value: 'thailand', text: 'Thailand' },
  ],
  target_countries: [
    { value: 'afghanistan', text: 'Afghanistan' },
    { value: 'aland islands', text: 'Aland Islands' },
    { value: 'albania', text: 'Albania' },
    { value: 'algeria', text: 'Algeria' },
    { value: 'american samoa', text: 'American Samoa' },
    { value: 'andorra', text: 'Andorra' },
    { value: 'angola', text: 'Angola' },
    { value: 'anguilla', text: 'Anguilla' },
    { value: 'antarctica', text: 'Antarctica' },
    { value: 'antigua and barbuda', text: 'Antigua and Barbuda' },
    { value: 'argentina', text: 'Argentina' },
    { value: 'armenia', text: 'Armenia' },
    { value: 'aruba', text: 'Aruba' },
    { value: 'australia', text: 'Australia' },
    { value: 'austria', text: 'Austria' },
    { value: 'azerbaijan', text: 'Azerbaijan' },
    { value: 'bahamas', text: 'Bahamas' },
    { value: 'bahrain', text: 'Bahrain' },
    { value: 'bangladesh', text: 'Bangladesh' },
    { value: 'barbados', text: 'Barbados' },
    { value: 'belarus', text: 'Belarus' },
    { value: 'belgium', text: 'Belgium' },
    { value: 'belize', text: 'Belize' },
    { value: 'benin', text: 'Benin' },
    { value: 'bermuda', text: 'Bermuda' },
    { value: 'bhutan', text: 'Bhutan' },
    { value: 'bolivia', text: 'Bolivia' },
    { value: 'bonaire, saint eustatius and saba ', text: 'Bonaire, Saint Eustatius and Saba ' },
    { value: 'bosnia and herzegovina', text: 'Bosnia and Herzegovina' },
    { value: 'botswana', text: 'Botswana' },
    { value: 'bouvet island', text: 'Bouvet Island' },
    { value: 'brazil', text: 'Brazil' },
    { value: 'british indian ocean territory', text: 'British Indian Ocean Territory' },
    { value: 'british virgin islands', text: 'British Virgin Islands' },
    { value: 'brunei', text: 'Brunei' },
    { value: 'bulgaria', text: 'Bulgaria' },
    { value: 'burkina faso', text: 'Burkina Faso' },
    { value: 'burundi', text: 'Burundi' },
    { value: 'cambodia', text: 'Cambodia' },
    { value: 'cameroon', text: 'Cameroon' },
    { value: 'canada', text: 'Canada' },
    { value: 'cape verde', text: 'Cape Verde' },
    { value: 'cayman islands', text: 'Cayman Islands' },
    { value: 'central african republic', text: 'Central African Republic' },
    { value: 'chad', text: 'Chad' },
    { value: 'chile', text: 'Chile' },
    { value: 'china', text: 'China' },
    { value: 'christmas island', text: 'Christmas Island' },
    { value: 'cocos islands', text: 'Cocos Islands' },
    { value: 'colombia', text: 'Colombia' },
    { value: 'comoros', text: 'Comoros' },
    { value: 'cook islands', text: 'Cook Islands' },
    { value: 'costa rica', text: 'Costa Rica' },
    { value: 'croatia', text: 'Croatia' },
    { value: 'cuba', text: 'Cuba' },
    { value: 'curacao', text: 'Curacao' },
    { value: 'cyprus', text: 'Cyprus' },
    { value: 'czech republic', text: 'Czech Republic' },
    { value: 'democratic republic of the congo', text: 'Democratic Republic of the Congo' },
    { value: 'denmark', text: 'Denmark' },
    { value: 'djibouti', text: 'Djibouti' },
    { value: 'dominica', text: 'Dominica' },
    { value: 'dominican republic', text: 'Dominican Republic' },
    { value: 'east timor', text: 'East Timor' },
    { value: 'ecuador', text: 'Ecuador' },
    { value: 'egypt', text: 'Egypt' },
    { value: 'el salvador', text: 'El Salvador' },
    { value: 'equatorial guinea', text: 'Equatorial Guinea' },
    { value: 'eritrea', text: 'Eritrea' },
    { value: 'estonia', text: 'Estonia' },
    { value: 'ethiopia', text: 'Ethiopia' },
    { value: 'falkland islands', text: 'Falkland Islands' },
    { value: 'faroe islands', text: 'Faroe Islands' },
    { value: 'fiji', text: 'Fiji' },
    { value: 'finland', text: 'Finland' },
    { value: 'france', text: 'France' },
    { value: 'french guiana', text: 'French Guiana' },
    { value: 'french polynesia', text: 'French Polynesia' },
    { value: 'french southern territories', text: 'French Southern Territories' },
    { value: 'gabon', text: 'Gabon' },
    { value: 'gambia', text: 'Gambia' },
    { value: 'georgia', text: 'Georgia' },
    { value: 'germany', text: 'Germany' },
    { value: 'ghana', text: 'Ghana' },
    { value: 'gibraltar', text: 'Gibraltar' },
    { value: 'greece', text: 'Greece' },
    { value: 'greenland', text: 'Greenland' },
    { value: 'grenada', text: 'Grenada' },
    { value: 'guadeloupe', text: 'Guadeloupe' },
    { value: 'guam', text: 'Guam' },
    { value: 'guatemala', text: 'Guatemala' },
    { value: 'guernsey', text: 'Guernsey' },
    { value: 'guinea', text: 'Guinea' },
    { value: 'guinea-bissau', text: 'Guinea-Bissau' },
    { value: 'guyana', text: 'Guyana' },
    { value: 'haiti', text: 'Haiti' },
    { value: 'heard island and mcdonald islands', text: 'Heard Island and McDonald Islands' },
    { value: 'honduras', text: 'Honduras' },
    { value: 'hong kong', text: 'Hong Kong' },
    { value: 'hungary', text: 'Hungary' },
    { value: 'iceland', text: 'Iceland' },
    { value: 'india', text: 'India' },
    { value: 'indonesia', text: 'Indonesia' },
    { value: 'iran', text: 'Iran' },
    { value: 'iraq', text: 'Iraq' },
    { value: 'ireland', text: 'Ireland' },
    { value: 'isle of man', text: 'Isle of Man' },
    { value: 'israel', text: 'Israel' },
    { value: 'italy', text: 'Italy' },
    { value: 'ivory coast', text: 'Ivory Coast' },
    { value: 'jamaica', text: 'Jamaica' },
    { value: 'japan', text: 'Japan' },
    { value: 'jersey', text: 'Jersey' },
    { value: 'jordan', text: 'Jordan' },
    { value: 'kazakhstan', text: 'Kazakhstan' },
    { value: 'kenya', text: 'Kenya' },
    { value: 'kiribati', text: 'Kiribati' },
    { value: 'kosovo', text: 'Kosovo' },
    { value: 'kuwait', text: 'Kuwait' },
    { value: 'kyrgyzstan', text: 'Kyrgyzstan' },
    { value: 'laos', text: 'Laos' },
    { value: 'latvia', text: 'Latvia' },
    { value: 'lebanon', text: 'Lebanon' },
    { value: 'lesotho', text: 'Lesotho' },
    { value: 'liberia', text: 'Liberia' },
    { value: 'libya', text: 'Libya' },
    { value: 'liechtenstein', text: 'Liechtenstein' },
    { value: 'lithuania', text: 'Lithuania' },
    { value: 'luxembourg', text: 'Luxembourg' },
    { value: 'macao', text: 'Macao' },
    { value: 'macedonia', text: 'Macedonia' },
    { value: 'madagascar', text: 'Madagascar' },
    { value: 'malawi', text: 'Malawi' },
    { value: 'malaysia', text: 'Malaysia' },
    { value: 'maldives', text: 'Maldives' },
    { value: 'mali', text: 'Mali' },
    { value: 'malta', text: 'Malta' },
    { value: 'marshall islands', text: 'Marshall Islands' },
    { value: 'martinique', text: 'Martinique' },
    { value: 'mauritania', text: 'Mauritania' },
    { value: 'mauritius', text: 'Mauritius' },
    { value: 'mayotte', text: 'Mayotte' },
    { value: 'mexico', text: 'Mexico' },
    { value: 'micronesia', text: 'Micronesia' },
    { value: 'moldova', text: 'Moldova' },
    { value: 'monaco', text: 'Monaco' },
    { value: 'mongolia', text: 'Mongolia' },
    { value: 'montenegro', text: 'Montenegro' },
    { value: 'montserrat', text: 'Montserrat' },
    { value: 'morocco', text: 'Morocco' },
    { value: 'mozambique', text: 'Mozambique' },
    { value: 'myanmar', text: 'Myanmar' },
    { value: 'namibia', text: 'Namibia' },
    { value: 'nauru', text: 'Nauru' },
    { value: 'nepal', text: 'Nepal' },
    { value: 'netherlands', text: 'Netherlands' },
    { value: 'new caledonia', text: 'New Caledonia' },
    { value: 'new zealand', text: 'New Zealand' },
    { value: 'nicaragua', text: 'Nicaragua' },
    { value: 'niger', text: 'Niger' },
    { value: 'nigeria', text: 'Nigeria' },
    { value: 'niue', text: 'Niue' },
    { value: 'norfolk island', text: 'Norfolk Island' },
    { value: 'north korea', text: 'North Korea' },
    { value: 'northern mariana islands', text: 'Northern Mariana Islands' },
    { value: 'norway', text: 'Norway' },
    { value: 'oman', text: 'Oman' },
    { value: 'pakistan', text: 'Pakistan' },
    { value: 'palau', text: 'Palau' },
    { value: 'palestinian territory', text: 'Palestinian Territory' },
    { value: 'panama', text: 'Panama' },
    { value: 'papua new guinea', text: 'Papua New Guinea' },
    { value: 'paraguay', text: 'Paraguay' },
    { value: 'peru', text: 'Peru' },
    { value: 'philippines', text: 'Philippines' },
    { value: 'pitcairn', text: 'Pitcairn' },
    { value: 'poland', text: 'Poland' },
    { value: 'portugal', text: 'Portugal' },
    { value: 'puerto rico', text: 'Puerto Rico' },
    { value: 'qatar', text: 'Qatar' },
    { value: 'republic of the congo', text: 'Republic of the Congo' },
    { value: 'reunion', text: 'Reunion' },
    { value: 'romania', text: 'Romania' },
    { value: 'russia', text: 'Russia' },
    { value: 'rwanda', text: 'Rwanda' },
    { value: 'saint barthelemy', text: 'Saint Barthelemy' },
    { value: 'saint helena', text: 'Saint Helena' },
    { value: 'saint kitts and nevis', text: 'Saint Kitts and Nevis' },
    { value: 'saint lucia', text: 'Saint Lucia' },
    { value: 'saint martin', text: 'Saint Martin' },
    { value: 'saint pierre and miquelon', text: 'Saint Pierre and Miquelon' },
    { value: 'saint vincent and the grenadines', text: 'Saint Vincent and the Grenadines' },
    { value: 'samoa', text: 'Samoa' },
    { value: 'san marino', text: 'San Marino' },
    { value: 'sao tome and principe', text: 'Sao Tome and Principe' },
    { value: 'saudi arabia', text: 'Saudi Arabia' },
    { value: 'senegal', text: 'Senegal' },
    { value: 'serbia', text: 'Serbia' },
    { value: 'seychelles', text: 'Seychelles' },
    { value: 'sierra leone', text: 'Sierra Leone' },
    { value: 'singapore', text: 'Singapore' },
    { value: 'sint maarten', text: 'Sint Maarten' },
    { value: 'slovakia', text: 'Slovakia' },
    { value: 'slovenia', text: 'Slovenia' },
    { value: 'solomon islands', text: 'Solomon Islands' },
    { value: 'somalia', text: 'Somalia' },
    { value: 'south africa', text: 'South Africa' },
    { value: 'south georgia and the south sandwich islands', text: 'South Georgia and the South Sandwich Islands' },
    { value: 'south korea', text: 'South Korea' },
    { value: 'south sudan', text: 'South Sudan' },
    { value: 'spain', text: 'Spain' },
    { value: 'sri lanka', text: 'Sri Lanka' },
    { value: 'sudan', text: 'Sudan' },
    { value: 'suriname', text: 'Suriname' },
    { value: 'svalbard and jan mayen', text: 'Svalbard and Jan Mayen' },
    { value: 'swaziland', text: 'Swaziland' },
    { value: 'sweden', text: 'Sweden' },
    { value: 'switzerland', text: 'Switzerland' },
    { value: 'syria', text: 'Syria' },
    { value: 'taiwan', text: 'Taiwan' },
    { value: 'tajikistan', text: 'Tajikistan' },
    { value: 'tanzania', text: 'Tanzania' },
    { value: 'thailand', text: 'Thailand' },
    { value: 'togo', text: 'Togo' },
    { value: 'tokelau', text: 'Tokelau' },
    { value: 'tonga', text: 'Tonga' },
    { value: 'trinidad and tobago', text: 'Trinidad and Tobago' },
    { value: 'tunisia', text: 'Tunisia' },
    { value: 'turkey', text: 'Turkey' },
    { value: 'turkmenistan', text: 'Turkmenistan' },
    { value: 'turks and caicos islands', text: 'Turks and Caicos Islands' },
    { value: 'tuvalu', text: 'Tuvalu' },
    { value: 'u.s. virgin islands', text: 'U.S. Virgin Islands' },
    { value: 'uganda', text: 'Uganda' },
    { value: 'ukraine', text: 'Ukraine' },
    { value: 'united arab emirates', text: 'United Arab Emirates' },
    { value: 'united kingdom', text: 'United Kingdom' },
    { value: 'united states', text: 'United States' },
    { value: 'united states minor outlying islands', text: 'United States Minor Outlying Islands' },
    { value: 'uruguay', text: 'Uruguay' },
    { value: 'uzbekistan', text: 'Uzbekistan' },
    { value: 'vanuatu', text: 'Vanuatu' },
    { value: 'vatican', text: 'Vatican' },
    { value: 'venezuela', text: 'Venezuela' },
    { value: 'vietnam', text: 'Vietnam' },
    { value: 'wallis and futuna', text: 'Wallis and Futuna' },
    { value: 'western sahara', text: 'Western Sahara' },
    { value: 'yemen', text: 'Yemen' },
    { value: 'zambia', text: 'Zambia' },
    { value: 'zimbabwe', text: 'Zimbabwe' },
  ],
  partner_countries: [
    { value: 'ukraine', text: 'Ukraine' },
    { value: 'israel', text: 'Israel' },
    { value: 'belarus', text: 'Belarus' },
    { value: 'belgium', text: 'Belgium' },
    { value: 'bulgaria', text: 'Bulgaria' },
    { value: 'croatia', text: 'Croatia' },
    { value: 'czechia', text: 'Czechia' },
    { value: 'denmark', text: 'Denmark' },
    { value: 'estonia', text: 'Estonia' },
    { value: 'finland', text: 'Finland' },
    { value: 'france', text: 'France' },
    { value: 'germany', text: 'Germany' },
    { value: 'greece', text: 'Greece' },
    { value: 'hungary', text: 'Hungary' },
    { value: 'italy', text: 'Italy' },
    { value: 'latvia', text: 'Latvia' },
    { value: 'lithuania', text: 'Lithuania' },
    { value: 'moldova', text: 'Moldova' },
    { value: 'monaco', text: 'Monaco' },
    { value: 'netherlands', text: 'Netherlands' },
    { value: 'norway', text: 'Norway' },
    { value: 'poland', text: 'Poland' },
    { value: 'portugal', text: 'Portugal' },
    { value: 'romania', text: 'Romania' },
    { value: 'russia', text: 'Russia' },
    { value: 'slovakia', text: 'Slovakia' },
    { value: 'slovenia', text: 'Slovenia' },
    { value: 'spain', text: 'Spain' },
    { value: 'sweden', text: 'Sweden' },
    { value: 'switzerland', text: 'Switzerland' },
    { value: 'united Kingdom', text: 'United Kingdom' },
    { value: 'united States', text: 'United States' },
    { value: 'uzbekistan', text: 'Uzbekistan' },
    { value: 'kazakhstan', text: 'Kazakhstan' },
    { value: 'tajikistan', text: 'Tajikistan' },
    { value: 'canada', text: 'Canada' },
    { value: 'peru', text: 'Peru' },
    { value: 'indonesia', text: 'Indonesia' },
    { value: 'thailand', text: 'Thailand' },
    { value: 'bangladesh', text: 'Bangladesh' },
    { value: 'turkey', text: 'Turkey' },
    { value: 'india', text: 'India' },
    { value: 'ireland', text: 'Ireland' },
    { value: 'luxembourg', text: 'Luxembourg' },
    { value: 'australia', text: 'Australia' },
  ],
  cities: ['Tel Aviv', 'Chernihiv', 'Cherkasy',
    'Dnepropetrovsk', 'Donetsk', 'Ivano-Frankivsk', 'Kharkiv', 'Kiev', 'Gnidyn',
    'Kirovohrad', 'Khmelnytskyi', 'Luhansk', 'Lviv',
    'Mykolaiv', 'Odessa', 'Poltava',
    'Vinnytsya', 'Volyn', 'Zakarpattia', 'Zaporizhia', 'Moscow', 'Saint Petersburg', 'Yekaterinburg',
    'Krasnodar', 'Rostov-on-Don', 'Samara', 'Novosibirsk', 'Kazan’', 'Ufa', 'Mineralnye Vody', 'Yalta', 'Simferopol', 'Sevastopol', 'Yevpatoria',
    'Pasadena', 'Tashkent', 'Andijan', 'Fergana', 'Namangan', 'Samarkand', 'Bukhara', 'Qarshi', 'Dushanbe', 'Khujand',
    'Dnipro', 'Dniprodzerzhynsk', 'Nikopol', 'Pavlohrad',
    'Chornomors’k', 'Dubno', 'Lutsk', 'Dnipro', 'Odesa', 'Kyiv',
    'Oleksandriya', 'Oleshky', 'Artsyz', 'Okhtyrka', 'Balakliya', 'Balta', 'Bar',
    'Baranivka', 'Barvinkove', 'Bakhmach', 'Bakhmut', 'Bashtanka', 'Bila Tserkva',
    'Bilhorod-Dnistrovskyi', "Bilozers'ke", 'Bilopillya', 'Berdychiv', "Berdyans'k",
    'Berehove', 'Berezhany', "Berezan'", 'Berezne', 'Beryslav', 'Bershad',
    "Bobrynets'", 'Bobrovytsya', 'Bohodukhiv', 'Bohuslav', 'Bolhrad', 'Bolekhiv',
    'Borzna', 'Boryslav', 'Boryspil', 'Borshchiv', 'Boyarka', 'Brovary', 'Brody',
    'Burshtyn', "Buryn'", 'Bucha', 'Buchach', 'Varash', 'Vasylivka', 'Vasylkiv',
    'Vatutine', "Vakhrushev'", 'Vashkivtsi', "Verkhn'odniprovs'k", 'Vynnyky',
    'Vinnytsia', 'Vyshneve', 'Volodymyr-Volynskyi', 'Voznesensk', 'Volnovakha',
    'Volochysk', "Vovchans'k", "Vil'nohirs'k", "Vil'nyans'k", 'Vyshhorod', 'Hadyach',
    'Haivoron', 'Haisyn', 'Genichesk', 'Hlobyne', 'Hlukhiv', "Hnivan'", "Hola Prystan'",
    'Horishni Plavni', 'Hirnyk', 'Horodyshche', 'Horodnya', 'Horodok', 'Hrebinka', 'Derazhnya',
    'Derhachi', 'Dniprorudne', "Dobropol'ye", "Dolyns'ka", 'Drohobych', 'Druzhkovka',
    'Dunaivtsi', 'Zhashkiv', 'Zhovti Vody', 'Zhydachiv', 'Zhytomyr', 'Zhmerynka', 'Zhovkva',
    'Zbarazh', 'Zvenyhorodka', 'Zdolbuniv', 'Zolote', 'Zolotonosha', 'Zolochiv', 'Zuhres',
    'Izmail', 'Izyum', 'Izyaslav', 'Illintsi', "Teplohirs'k", 'Irpin', 'Irshava', 'Ichnya',
    'Kaharlyk', 'Kozyatyn', 'Kalynivka', 'Kalush', 'Kamyanets-Podilsky', 'Kamianka', 'Kamianka-Buzka',
    'Kamenka-Na-Dnepre', 'Kamianske', 'Kamin-Kashyrskyi', 'Kaniv', 'Karlivka', 'Kakhovka',
    'Kivertsi', 'Kiliya', 'Kobelyaky', 'Kovel', 'Kolomyia', 'Konotop', 'Kostyantynivka',
    'Korosten', 'Korostyshiv', "Korsun'-Shevchenkivs'kyy", 'Koryukivka', "Kostopil'", 'Kramatorsk',
    'Krasyliv', 'Krasnohrad', 'Kremenets', 'Kremenchuk', 'Kryvyi Rih', 'Krolevets', 'Kropyvnytskyi',
    'Kupiansk', 'Ladyzhyn', 'Lebedyn', 'Lyman', 'Lisichansk', 'Lozova', 'Lokhvytsya', 'Lubny',
    'Lutugino', 'Liuboml', 'Lyubotyn', 'Malyn', "Marhanets'", 'Mariupol', 'Mala Vyska', 'Melitopol',
    'Merefa', 'Myrhorod', 'Myrnohrad', 'Myronivka', 'Mukacheve', 'Nadvirna', 'Nizhyn',
    'Nemyriv', 'Netishyn', 'Nova Kakhovka', 'Nova Odesa', 'Novyi Buh', "Novovolyns'k",
    'Khorol', "Uman'", "Trostyanets'", 'Ternovka', 'Talne', 'Tavriysk',
    'Starokostyantyniv I', 'Pokrovsk', 'Pokrov', 'Pereiaslav-Khmelnytskyi', 'Mohyliv-Podilskyi',
    "Pervomays'kyy", 'Pervomaysk', 'Novyi Rozdil', 'Novohrad-Volynskyi', "Novhorod-Sivers'kyy",
    "Yampil'", 'Yahotyn', 'Yavoriv', "Yuzhnoukrains'k", 'Yuzhne', 'Enerhodar',
    'Shpola', 'Shostka', 'Shepetivka', 'Chuhuiv', 'Chortkiv', 'Chyhyryn', 'Chernivtsi',
    'Chornomorsk', 'Chervonohrad', 'Khust', 'Khotyn',
    "Khmil'nyk", 'Kherson', 'Fastiv',
    'Uzhhorod', 'Ukrainka', 'Uzyn', 'Ugledar', 'Tulchyn', 'Tokmak',
    'Terebovlya', 'Tetiiv', 'Ternopil', 'Tatarbunary', 'Tarashcha',
    "Sukhodil's'k", 'Sumy', 'Stryi', "Storozhynets'", 'Stakhanov', "Starobil's'k", 'Soledar',
    "Sokal'", 'Sniatyn', 'Shchors ', 'Snihurivka', 'Smila', 'Sloviansk', 'Slavutych', 'Slavuta', 'Skvyra',
    'Skadowsk', "Synel'nykove", 'Selidovo', "Syeverodonets'k", "Svitlovods'k", 'Svatove', 'Svalyava',
    'Sarny', 'Sambir', 'Rubizhne', 'Romny', 'Rozhyshche', 'Rivne', 'Rakhiv', 'Rozdilna', 'Radyvyliv',
    'Radomyshl', "P'yatykhatky", "Putyvl'", "Prymors'k", 'Pryluky', 'Popasnaya',
    'Polohy', "Podil's'k", 'Pidhorodne', 'Pohrebyshche', 'Pyryatyn', 'Pershotravensk',
    'Pereshchepino', "Pereval'sk", 'Ochakiv', 'Ostroh', 'Orikhiv',
    'Ovruch', 'Obukhiv', 'Nosivka', 'Novoukrayinka', 'Novomoskovsk', "Novodnistrovs'k",
    'Novomyrhorod', 'Novogrodovka', 'New York', 'Brooklyn', 'Manhattan', 'Queens', 'Bronx', 'Manhattan Island',
    'Long Island', 'Staten Island', 'Acre', 'Afula', 'Arad', 'Ariel', 'Ashdod', 'Ashkelon', 'Bat Yam', 'Beersheba',
    'Beit Shean', 'Beit Shemesh', 'Beitar Illit', 'Bnei Brak', 'Dimona', 'Eilat', 'Elad', 'Givat Shmuel', 'Givatayim',
    'Hadera', 'Haifa', 'Herzliya', 'Hod HaSharon', 'Holon', 'Jerusalem', 'Karmiel', 'Kiryat Ata', 'Kiryat Bialik',
    'Kiryat Gat', 'Kiryat Malakhi', 'Kiryat Motzkin', 'Kiryat Ono', 'Kiryat Shmona', 'Kiryat Yam', 'Kfar Saba', 'Lod',
    'Maale Adumim', 'Maalot-Tarshiha', 'Migdal HaEmek', 'Modiin-Maccabim-Reut', 'Modiin Illit', 'Nahariya', 'Nazareth Illit',
    'Nesher', 'Ness Ziona', 'Netanya', 'Netivot', 'Ofakim', 'Or Akiva', 'Or Yehuda', 'Petah Tikva', 'Raanana', 'Ramat Gan',
    'Ramat HaSharon', 'Ramla', 'Rehovot', 'Rishon LeZion', 'Rosh HaAyin', 'Safed', 'Sderot', 'Tel Aviv-Yaffo', 'Tiberias', 'Tirat Carmel', 'Yavne', 'Yehud-Monosson', 'Yokneam',
    'Mumbai', 'Delhi', 'Kolkata', 'Chennai', 'Bangalore', 'Hyderabad', 'Ahmedabad', 'Pune', 'Surat', 'Jaipur', 'Kanpur', 'Lucknow',
    'Nagpur', 'Ghaziabad', 'Indore', 'Coimbatore', 'Kochi', 'Patna', 'Kozhikode',
  ],
  weeks_days: [
    { value: 'Monday', text: 'Monday' },
    { value: 'Tuesday', text: 'Tuesday' },
    { value: 'Wednesday', text: 'Wednesday' },
    { value: 'Thursday', text: 'Thursday' },
    { value: 'Friday', text: 'Friday' },
    { value: 'Saturday', text: 'Saturday' },
    { value: 'Sunday', text: 'Sunday' },
  ],
  hours_nums: [
    { value: '0', text: '0' },
    { value: '1', text: '1' },
    { value: '2', text: '2' },
    { value: '3', text: '3' },
    { value: '4', text: '4' },
    { value: '5', text: '5' },
    { value: '6', text: '6' },
    { value: '7', text: '7' },
    { value: '8', text: '8' },
    { value: '9', text: '9' },
    { value: '10', text: '10' },
    { value: '11', text: '11' },
    { value: '12', text: '12' },
    { value: '13', text: '13' },
    { value: '14', text: '14' },
    { value: '15', text: '15' },
    { value: '16', text: '16' },
    { value: '17', text: '17' },
    { value: '18', text: '18' },
    { value: '19', text: '19' },
    { value: '20', text: '20' },
    { value: '21', text: '21' },
    { value: '22', text: '22' },
    { value: '23', text: '23' },
  ],
  languages: [
    { value: 'Arabic', text: 'Arabic' },
    { value: 'Amharic', text: 'Amharic' },
    { value: 'Assamese', text: 'Assamese' },
    { value: 'Awadhi', text: 'Awadhi' },
    { value: 'Azerbaijani', text: 'Azerbaijani' },
    { value: 'Belorussian', text: 'Belorussian' },
    { value: 'Bengali', text: 'Bengali' },
    { value: 'Bhojpuri', text: 'Bhojpuri' },
    { value: 'Burmese', text: 'Burmese' },
    { value: 'Chinese', text: 'Chinese' },
    { value: 'Czech', text: 'Czech' },
    { value: 'Dakhni', text: 'Dakhni' },
    { value: 'Dutch', text: 'Dutch' },
    { value: 'English', text: 'English' },
    { value: 'Filipino', text: 'Filipino' },
    { value: 'French', text: 'French' },
    { value: 'Fula', text: 'Fula' },
    { value: 'German', text: 'German' },
    { value: 'Greek', text: 'Greek' },
    { value: 'Gujarati', text: 'Gujarati' },
    { value: 'Hausa–Gwandara', text: 'Hausa–Gwandara' },
    { value: 'Hebrew', text: 'Hebrew' },
    { value: 'Hindi', text: 'Hindi' },
    { value: 'Hungarian', text: 'Hungarian' },
    { value: 'Igbo', text: 'Igbo' },
    { value: 'Indonesian', text: 'Indonesian' },
    { value: 'Ispanish', text: 'Ispanish' },
    { value: 'Italian', text: 'Italian' },
    { value: 'Japanese', text: 'Japanese' },
    { value: 'Javanese', text: 'Javanese' },
    { value: 'Kannada', text: 'Kannada' },
    { value: 'Korean', text: 'Korean' },
    { value: 'Kurdish', text: 'Kurdish' },
    { value: 'Magahi', text: 'Magahi' },
    { value: 'Maithili', text: 'Maithili' },
    { value: 'Malagasy', text: 'Malagasy' },
    { value: 'Malay', text: 'Malay' },
    { value: 'Malayalam', text: 'Malayalam' },
    { value: 'Marathi', text: 'Marathi' },
    { value: 'Nepali', text: 'Nepali' },
    { value: 'Odia', text: 'Odia' },
    { value: 'Oromo', text: 'Oromo' },
    { value: 'Pashto', text: 'Pashto' },
    { value: 'Persian', text: 'Persian' },
    { value: 'Polish', text: 'Polish' },
    { value: 'Portuguese', text: 'Portuguese' },
    { value: 'Punjabi', text: 'Punjabi' },
    { value: 'Romanian', text: 'Romanian' },
    { value: 'Rundi', text: 'Rundi' },
    { value: 'Russian', text: 'Russian' },
    { value: 'Shona', text: 'Shona' },
    { value: 'Sindhi', text: 'Sindhi' },
    { value: 'Tagalog', text: 'Tagalog' },
    { value: 'Tamil', text: 'Tamil' },
    { value: 'Telugu', text: 'Telugu' },
    { value: 'Thai', text: 'Thai' },
    { value: 'Turkish', text: 'Turkish' },
    { value: 'Ukrainian', text: 'Ukrainian' },
    { value: 'Urdu', text: 'Urdu' },
    { value: 'Uzbek', text: 'Uzbek' },
    { value: 'Vietnamese', text: 'Vietnamese' },
    { value: 'Yoruba', text: 'Yoruba' },
    { value: 'Zhuang', text: 'Zhuang' },
  ],
  statuses: [
    { value: 'active', text: 'Active' },
    { value: 'inactive', text: 'Inactive' },
  ],
  criteo_ads_types: [
    { value: 'banner', text: 'Banner' },
    { value: 'native', text: 'Native' },
  ],
  encodings: [
    { value: 'auto', text: 'auto' },
    { value: 'utf8', text: 'utf8' },
    { value: 'cp1251', text: 'cp1251' },
    { value: 'iso-8859-1', text: 'iso-8859-1' },
  ],
  devices: [
    { value: 'iPhone', text: 'iPhone' },
    { value: 'iPad', text: 'iPad' },
    { value: 'Android', text: 'Android' },
    { value: 'Windows Phone', text: 'Windows Phone' },
    { value: 'Generic Smartphone', text: 'Generic Smartphone' },
  ],
  os: [
    { value: 'Android', text: 'Android' },
    { value: 'BlackBerry OS', text: 'BlackBerry OS' },
    { value: 'Chrome OS', text: 'Chrome OS' },
    { value: 'iOS', text: 'iOS' },
    { value: 'Linux', text: 'Linux' },
    { value: 'Mac OS', text: 'Mac OS' },
    { value: 'Mac OS X', text: 'Mac OS X' },
    { value: 'webOS', text: 'webOS' },
    { value: 'Windows XP', text: 'Windows XP' },
    { value: 'Windows 7', text: 'Windows 7' },
    { value: 'Windows 8', text: 'Windows 8' },
    { value: 'Windows 8.1', text: 'Windows 8.1' },
    { value: 'Windows 10', text: 'Windows 10' },
    { value: 'Windows Phone', text: 'Windows Phone' },
  ],
  browsers: [
    { value: 'Chrome', text: 'Chrome' },
    { value: 'Chromium', text: 'Chromium' },
    { value: 'Firefox', text: 'Firefox' },
    { value: 'IE', text: 'IE' },
    { value: 'Edge', text: 'Edge' },
    { value: 'Mobile Safari', text: 'Mobile Safari' },
    { value: 'Mozilla', text: 'Mozilla' },
    { value: 'Opera', text: 'Opera' },
    { value: 'Safari', text: 'Safari' },
    { value: 'Yandex Browser', text: 'Yandex Browser' },
  ],
  usersLanguage: [
    { value: 'ua', text: 'ua' },
    { value: 'ru', text: 'ru' },
    { value: 'en', text: 'en' },
    { value: 'he', text: 'he' },
    { value: 'uz', text: 'uz' },
  ],
  parser: [
    { value: 'core.parser.processor.opengraph.parser.OpenGraphParser', text: 'OpenGraphParser' },
    { value: 'core.parser.processor.pquery.parser.PyQueryParser', text: 'PyQuery' },
    { value: 'core.parser.processor.rdability.parser.ReadabilityParser', text: 'ReadabilityParser' },
    { value: 'core.parser.processor.rdabilityapi.parser.ReadabilityAPIParser', text: 'ReadabilityAPIParser' },
    { value: 'core.parser.processor.dummy.parser.DummyParser', text: 'DummyParser' },
  ],
  date_parser: [
    { value: 'core.parser.processor.date.parser.DefaultDateParser', text: 'DefaultDateParser' },
    { value: 'core.parser.processor.date.parser.UrlRegexDateParser', text: 'UrlRegexDateParser' },
    { value: 'core.parser.processor.date.parser.PyQueryDateParser', text: 'PyQueryDateParser' },
  ],
  language_parser: [
    { value: 'core.parser.processor.language.parser.DefaultLanguageParser', text: 'DefaultLanguageParser' },
    { value: 'core.parser.processor.language.parser.UrlRegexLanguageParser', text: 'UrlRegexLanguageParser' },
    { value: 'core.parser.processor.language.parser.PyQueryLanguageParser', text: 'PyQueryLanguageParser' },
  ],
  clickfilter: [
    { value: 'core.clickfilters.OneToOne', text: 'One impression - One click' },
    { value: 'core.clickfilters.Dummy', text: 'Dummy' },
    { value: 'core.clickfilters.Lock', text: 'Lock user for some time after click' },
  ],
  optionsRadio: [
    { text: 'Inactive', value: 'inactive' },
    { text: 'Active', value: 'active' },
    { text: 'Negative', value: 'negative' },
  ],
  rss_versions: [
    { text: 'RSS 1.0', value: 'rss_1' },
    { text: 'RSS 1.1', value: 'rss_1_1' },
  ],
  campaignType: [
    { value: 'default', text: 'Default' },
    { value: 'video', text: 'Video' },
    { value: 'social', text: 'Social' },
    { value: 'adsense', text: 'Adsense' },
    { value: 'news', text: 'News' },
    { value: 'cpcfeed', text: 'CPC Feed' },
    { value: 'criteo', text: 'Criteo' },
    { value: 'postback', text: 'Postback' },
  ],
  campaignCategory: [
    { value: 'IAB24', text: 'Uncategorized' },
    { value: 'IAB1', text: 'Arts & Entertainment' },
    { value: 'IAB2', text: 'Automotive' },
    { value: 'IAB3', text: 'Business' },
    { value: 'IAB26', text: 'Illegal Content' },
    { value: 'IAB25', text: 'Non-Standard Content' },
    { value: 'IAB23', text: 'Religion & Spirituality' },
    { value: 'IAB22', text: 'Shopping' },
    { value: 'IAB21', text: 'Real Estate' },
    { value: 'IAB20', text: 'Travel' },
    { value: 'IAB18', text: 'Style & Fashion' },
    { value: 'IAB19', text: 'Technology & Computing' },
    { value: 'IAB12', text: 'News' },
    { value: 'IAB13', text: 'Personal Finance' },
    { value: 'IAB10', text: 'Home & Garden' },
    { value: 'IAB11', text: 'Law, Government, & Politics' },
    { value: 'IAB16', text: 'Pets' },
    { value: 'IAB17', text: 'Sports' },
    { value: 'IAB14', text: 'Society' },
    { value: 'IAB15', text: 'Science' },
    { value: 'IAB4', text: 'Careers' },
    { value: 'IAB5', text: 'Education' },
    { value: 'IAB6', text: 'Family & Parenting' },
    { value: 'IAB7', text: 'Health & Fitness' },
    { value: 'IAB8', text: 'Food & Drink' },
    { value: 'IAB9', text: 'Hobbies & Interests' },
  ],
  campaignSubCategory: {
    IAB1: [
      { value: 'IAB1', text: 'Arts & Entertainment' },
      { value: 'IAB1-2', text: 'Celebrity Fan/Gossip' },
      { value: 'IAB1-3', text: 'Fine Art' },
      { value: 'IAB1-4', text: 'Humor' },
      { value: 'IAB1-5', text: 'Movies' },
      { value: 'IAB1-6', text: 'Music' },
      { value: 'IAB1-1', text: 'Books & Literature' },
      { value: 'IAB1-7', text: 'Television' },
    ],
    IAB2: [
      { value: 'IAB2-22', text: 'Vintage Cars' },
      { value: 'IAB2-23', text: 'Wagon' },
      { value: 'IAB2-20', text: 'Sedan' },
      { value: 'IAB2-21', text: 'Trucks & Accessories' },
      { value: 'IAB2-13', text: 'Luxury' },
      { value: 'IAB2-12', text: 'Hybrvalue' },
      { value: 'IAB2-11', text: 'Hatchback' },
      { value: 'IAB2-10', text: 'Electric Vehicle' },
      { value: 'IAB2-17', text: 'Performance Vehicles' },
      { value: 'IAB2-16', text: 'Off-Road Vehicles' },
      { value: 'IAB2-15', text: 'Motorcycles' },
      { value: 'IAB2-14', text: 'Minivan' },
      { value: 'IAB2-19', text: 'Road-Svaluee Assistance' },
      { value: 'IAB2-18', text: 'Pickup' },
      { value: 'IAB2', text: 'Automotive' },
      { value: 'IAB2-9', text: 'Diesel' },
      { value: 'IAB2-8', text: 'Crossover' },
      { value: 'IAB2-3', text: 'Buying/Selling Cars' },
      { value: 'IAB2-2', text: 'Auto Repair' },
      { value: 'IAB2-1', text: 'Auto Parts' },
      { value: 'IAB2-7', text: 'Coupe' },
      { value: 'IAB2-6', text: 'Convertible' },
      { value: 'IAB2-5', text: 'Certified Pre-Owned' },
      { value: 'IAB2-4', text: 'Car Culture' },
    ],
    IAB3: [
      { value: 'IAB3-8', text: 'Green Solutions' },
      { value: 'IAB3-9', text: 'Human Resources' },
      { value: 'IAB3-6', text: 'Forestry' },
      { value: 'IAB3-7', text: 'Government' },
      { value: 'IAB3-4', text: 'Business Software' },
      { value: 'IAB3-5', text: 'Construction' },
      { value: 'IAB3-2', text: 'Agriculture' },
      { value: 'IAB3-3', text: 'Biotech/Biomedical' },
      { value: 'IAB3-1', text: 'Advertising' },
      { value: 'IAB3', text: 'Business' },
      { value: 'IAB3-10', text: 'Logistics' },
      { value: 'IAB3-11', text: 'Marketing' },
      { value: 'IAB3-12', text: 'Metals' },
    ],
    IAB4: [
      { value: 'IAB4-11', text: 'Career Advice' },
      { value: 'IAB4-10', text: 'U.S. Military' },
      { value: 'IAB4', text: 'Careers' },
      { value: 'IAB4-1', text: 'Career Planning' },
      { value: 'IAB4-3', text: 'Financial Avalue' },
      { value: 'IAB4-2', text: 'College' },
      { value: 'IAB4-5', text: 'Job Search' },
      { value: 'IAB4-4', text: 'Job Fairs' },
      { value: 'IAB4-7', text: 'Nursing' },
      { value: 'IAB4-6', text: 'Resume Writing/Advice' },
      { value: 'IAB4-9', text: 'Telecommuting' },
      { value: 'IAB4-8', text: 'Scholarships' },
    ],
    IAB5: [
      { value: 'IAB5-8', text: 'Language Learning' },
      { value: 'IAB5-9', text: 'Graduate School' },
      { value: 'IAB5-4', text: 'College Administration' },
      { value: 'IAB5-5', text: 'College Life' },
      { value: 'IAB5-6', text: 'Distance Learning' },
      { value: 'IAB5-7', text: 'English as a 2nd Language' },
      { value: 'IAB5-1', text: '7-12 Education' },
      { value: 'IAB5-2', text: 'Adult Education' },
      { value: 'IAB5-3', text: 'Art History' },
      { value: 'IAB5-14', text: 'Special Education' },
      { value: 'IAB5-15', text: 'Studying Business' },
      { value: 'IAB5-12', text: 'K-6 Educators' },
      { value: 'IAB5', text: 'Education' },
      { value: 'IAB5-10', text: 'Homeschooling' },
      { value: 'IAB5-11', text: 'Homework/Study Tips' },
      { value: 'IAB5-13', text: 'Private School' },
    ],
    IAB6: [
      { value: 'IAB6-7', text: 'Pregnancy' },
      { value: 'IAB6-6', text: 'Parenting teens' },
      { value: 'IAB6-5', text: 'Parenting - K-6 Kvalues' },
      { value: 'IAB6-4', text: 'Family Internet' },
      { value: 'IAB6-3', text: 'Daycare/Pre School' },
      { value: 'IAB6-1', text: 'Adoption' },
      { value: 'IAB6', text: 'Family & Parenting' },
      { value: 'IAB6-8', text: 'Special Needs Kvalues' },
      { value: 'IAB6-9', text: 'Eldercare' },
      { value: 'IAB6-2', text: 'Babies & Toddlers' },
    ],
    IAB7: [
      { value: 'IAB7-8', text: 'Autism/PDD' },
      { value: 'IAB7-9', text: 'Bipolar Disorder' },
      { value: 'IAB7-2', text: 'ADD' },
      { value: 'IAB7-3', text: 'AvalueS/HIV' },
      { value: 'IAB7-1', text: 'Exercise' },
      { value: 'IAB7-6', text: 'Arthritis' },
      { value: 'IAB7-7', text: 'Asthma' },
      { value: 'IAB7-4', text: 'Allergies' },
      { value: 'IAB7-5', text: 'Alternative Medicine' },
      { value: 'IAB7-45', text: 'Women\'s Health' },
      { value: 'IAB7-44', text: 'Weight Loss' },
      { value: 'IAB7-43', text: 'Thyrovalue Disease' },
      { value: 'IAB7-42', text: 'Substance Abuse' },
      { value: 'IAB7-41', text: 'Smoking Cessation' },
      { value: 'IAB7-40', text: 'Sleep Disorders' },
      { value: 'IAB7-10', text: 'Brain Tumor' },
      { value: 'IAB7-11', text: 'Cancer' },
      { value: 'IAB7-12', text: 'Cholesterol' },
      { value: 'IAB7-13', text: 'Chronic Fatigue Syndrome' },
      { value: 'IAB7-14', text: 'Chronic Pain' },
      { value: 'IAB7-15', text: 'Cold & Flu' },
      { value: 'IAB7-16', text: 'Deafness' },
      { value: 'IAB7-17', text: 'Dental Care' },
      { value: 'IAB7-18', text: 'Depression' },
      { value: 'IAB7-19', text: 'Dermatology' },
      { value: 'IAB7-29', text: 'Incontinence' },
      { value: 'IAB7-28', text: 'Incest/Abuse Support' },
      { value: 'IAB7-25', text: 'Herbs for Health' },
      { value: 'IAB7-24', text: 'Heart Disease' },
      { value: 'IAB7-27', text: 'IBS/Crohn\'s Disease' },
      { value: 'IAB7-26', text: 'Holistic Healing' },
      { value: 'IAB7-21', text: 'Epilepsy' },
      { value: 'IAB7-20', text: 'Diabetes' },
      { value: 'IAB7-23', text: 'Headaches/Migraines' },
      { value: 'IAB7-22', text: 'GERD/Acvalue Reflux' },
      { value: 'IAB7-38', text: 'Senior Health' },
      { value: 'IAB7-39', text: 'Sexuality' },
      { value: 'IAB7', text: 'Health & Fitness' },
      { value: 'IAB7-32', text: 'Nutrition' },
      { value: 'IAB7-33', text: 'Orthopedics' },
      { value: 'IAB7-30', text: 'Infertility' },
      { value: 'IAB7-31', text: 'Men\'s Health' },
      { value: 'IAB7-36', text: 'Physical Therapy' },
      { value: 'IAB7-37', text: 'Psychology/Psychiatry' },
      { value: 'IAB7-34', text: 'Panic/Anxiety Disorders' },
      { value: 'IAB7-35', text: 'Pediatrics' },
    ],
    IAB8: [
      { value: 'IAB8-18', text: 'Wine' },
      { value: 'IAB8-12', text: 'Health/Low-Fat Cooking' },
      { value: 'IAB8-16', text: 'Vegan' },
      { value: 'IAB8-17', text: 'Vegetarian' },
      { value: 'IAB8-15', text: 'Mexican Cuisine' },
      { value: 'IAB8-14', text: 'Japanese Cuisine' },
      { value: 'IAB8-5', text: 'Cocktails/Beer' },
      { value: 'IAB8-11', text: 'French Cuisine' },
      { value: 'IAB8-9', text: 'Dining Out' },
      { value: 'IAB8-8', text: 'Desserts & Baking' },
      { value: 'IAB8-10', text: 'Food Allergies' },
      { value: 'IAB8', text: 'Food & Drink' },
      { value: 'IAB8-4', text: 'Chinese Cuisine' },
      { value: 'IAB8-7', text: 'Cuisine-Specific' },
      { value: 'IAB8-6', text: 'Coffee/Tea' },
      { value: 'IAB8-1', text: 'American Cuisine' },
      { value: 'IAB8-13', text: 'Italian Cuisine' },
      { value: 'IAB8-3', text: 'Cajun/Creole' },
      { value: 'IAB8-2', text: 'Barbecues & Grilling' },
    ],
    IAB9: [
      { value: 'IAB9-30', text: 'Vvalueeo & Computer Games' },
      { value: 'IAB9-28', text: 'Screenwriting' },
      { value: 'IAB9-31', text: 'Woodworking' },
      { value: 'IAB9-21', text: 'Needlework' },
      { value: 'IAB9-8', text: 'Chess' },
      { value: 'IAB9-9', text: 'Cigars' },
      { value: 'IAB9-20', text: 'Magic & Illusion' },
      { value: 'IAB9-1', text: 'Art/Technology' },
      { value: 'IAB9', text: 'Hobbies & Interests' },
      { value: 'IAB9-2', text: 'Arts & Crafts' },
      { value: 'IAB9-3', text: 'Beadwork' },
      { value: 'IAB9-4', text: 'Bird-Watching' },
      { value: 'IAB9-5', text: 'Board Games/Puzzles' },
      { value: 'IAB9-6', text: 'Candle & Soap Making' },
      { value: 'IAB9-7', text: 'Card Games' },
      { value: 'IAB9-23', text: 'Photography' },
      { value: 'IAB9-22', text: 'Painting' },
      { value: 'IAB9-18', text: 'Investors & Patents' },
      { value: 'IAB9-19', text: 'Jewelry Making' },
      { value: 'IAB9-27', text: 'Scrapbooking' },
      { value: 'IAB9-26', text: 'Sci-Fi & Fantasy' },
      { value: 'IAB9-25', text: 'Roleplaying Games' },
      { value: 'IAB9-24', text: 'Radio' },
      { value: 'IAB9-12', text: 'Drawing/Sketching' },
      { value: 'IAB9-13', text: 'Freelance Writing' },
      { value: 'IAB9-10', text: 'Collecting' },
      { value: 'IAB9-11', text: 'Comic Books' },
      { value: 'IAB9-16', text: 'Guitar' },
      { value: 'IAB9-17', text: 'Home Recording' },
      { value: 'IAB9-14', text: 'Genealogy' },
      { value: 'IAB9-15', text: 'Getting Published' },
      { value: 'IAB9-29', text: 'Stamps & Coins' },
    ],
    IAB10: [
      { value: 'IAB10-7', text: 'Interior Decorating' },
      { value: 'IAB10-6', text: 'Home Theater' },
      { value: 'IAB10-5', text: 'Home Repair' },
      { value: 'IAB10-4', text: 'Gardening' },
      { value: 'IAB10-3', text: 'Environmental Safety' },
      { value: 'IAB10-2', text: 'Entertaining' },
      { value: 'IAB10-1', text: 'Appliances' },
      { value: 'IAB10', text: 'Home & Garden' },
      { value: 'IAB10-9', text: 'Remodeling & Construction' },
      { value: 'IAB10-8', text: 'Landscaping' },
    ],
    IAB11: [
      { value: 'IAB11-2', text: 'Legal Issues' },
      { value: 'IAB11-3', text: 'U.S. Government Resources' },
      { value: 'IAB11-1', text: 'Immigration' },
      { value: 'IAB11-4', text: 'Politics' },
      { value: 'IAB11-5', text: 'Commentary' },
      { value: 'IAB11', text: 'Law, Government, & Politics' },
    ],
    IAB12: [
      { value: 'IAB12', text: 'News' },
      { value: 'IAB12-1', text: 'International News' },
      { value: 'IAB12-3', text: 'Local News' },
      { value: 'IAB12-2', text: 'National News' },
    ],
    IAB13: [
      { value: 'IAB13-12', text: 'Tax Planning' },
      { value: 'IAB13-10', text: 'Retirement Planning' },
      { value: 'IAB13-11', text: 'Stocks' },
      { value: 'IAB13', text: 'Personal Finance' },
      { value: 'IAB13-8', text: 'Mutual Funds' },
      { value: 'IAB13-9', text: 'Options' },
      { value: 'IAB13-4', text: 'Financial Planning' },
      { value: 'IAB13-5', text: 'Hedge Fund' },
      { value: 'IAB13-6', text: 'Insurance' },
      { value: 'IAB13-7', text: 'Investing' },
      { value: 'IAB13-1', text: 'Beginning Investing' },
      { value: 'IAB13-2', text: 'Credit/Debt & Loans' },
      { value: 'IAB13-3', text: 'Financial News' },
    ],
    IAB14: [
      { value: 'IAB14-3', text: 'Gay Life' },
      { value: 'IAB14-2', text: 'Divorce Support' },
      { value: 'IAB14-1', text: 'Dating' },
      { value: 'IAB14-7', text: 'Weddings' },
      { value: 'IAB14-6', text: 'Teens' },
      { value: 'IAB14-5', text: 'Senior Living' },
      { value: 'IAB14-4', text: 'Marriage' },
      { value: 'IAB14-8', text: 'Ethnic Specific' },
      { value: 'IAB14', text: 'Society' },
    ],
    IAB15: [
      { value: 'IAB15-10', text: 'Weather' },
      { value: 'IAB15', text: 'Science' },
      { value: 'IAB15-8', text: 'Geography' },
      { value: 'IAB15-9', text: 'Botany' },
      { value: 'IAB15-6', text: 'Physics' },
      { value: 'IAB15-7', text: 'Space/Astronomy' },
      { value: 'IAB15-4', text: 'Geology' },
      { value: 'IAB15-5', text: 'Paranormal Phenomena' },
      { value: 'IAB15-2', text: 'Biology' },
      { value: 'IAB15-3', text: 'Chemistry' },
      { value: 'IAB15-1', text: 'Astrology' },
    ],
    IAB16: [
      { value: 'IAB16-5', text: 'Large Animals' },
      { value: 'IAB16-4', text: 'Dogs' },
      { value: 'IAB16-7', text: 'Veterinary Medicine' },
      { value: 'IAB16-6', text: 'Reptiles' },
      { value: 'IAB16-1', text: 'Aquariums' },
      { value: 'IAB16-3', text: 'Cats' },
      { value: 'IAB16-2', text: 'Birds' },
      { value: 'IAB16', text: 'Pets' },
    ],
    IAB17: [
      { value: 'IAB17-41', text: 'Volleyball' },
      { value: 'IAB17-40', text: 'Tennis' },
      { value: 'IAB17-43', text: 'Waterski/Wakeboard' },
      { value: 'IAB17-42', text: 'Walking' },
      { value: 'IAB17-44', text: 'World Soccer' },
      { value: 'IAB17-8', text: 'Climbing' },
      { value: 'IAB17-9', text: 'Cricket' },
      { value: 'IAB17-1', text: 'Auto Racing' },
      { value: 'IAB17-2', text: 'Baseball' },
      { value: 'IAB17-3', text: 'Bicycling' },
      { value: 'IAB17-4', text: 'Bodybuilding' },
      { value: 'IAB17-5', text: 'Boxing' },
      { value: 'IAB17-6', text: 'Canoeing/Kayaking' },
      { value: 'IAB17-7', text: 'Cheerleading' },
      { value: 'IAB17-38', text: 'Swimming' },
      { value: 'IAB17-39', text: 'Table Tennis/Ping-Pong' },
      { value: 'IAB17-34', text: 'Skateboarding' },
      { value: 'IAB17-35', text: 'Skiing' },
      { value: 'IAB17-36', text: 'Snowboarding' },
      { value: 'IAB17-37', text: 'Surfing/Body-Boarding' },
      { value: 'IAB17-30', text: 'Running/Jogging' },
      { value: 'IAB17-31', text: 'Sailing' },
      { value: 'IAB17-32', text: 'Saltwater Fishing' },
      { value: 'IAB17-33', text: 'Scuba Diving' },
      { value: 'IAB17', text: 'Sports' },
      { value: 'IAB17-29', text: 'Rugby' },
      { value: 'IAB17-28', text: 'Rodeo' },
      { value: 'IAB17-27', text: 'Pro Ice Hockey' },
      { value: 'IAB17-26', text: 'Pro Basketball' },
      { value: 'IAB17-25', text: 'Power & Motorcycles' },
      { value: 'IAB17-24', text: 'Paintball' },
      { value: 'IAB17-23', text: 'Olympics' },
      { value: 'IAB17-22', text: 'NASCAR Racing' },
      { value: 'IAB17-21', text: 'Mountain Biking' },
      { value: 'IAB17-20', text: 'Martial Arts' },
      { value: 'IAB17-12', text: 'Football' },
      { value: 'IAB17-13', text: 'Freshwater Fishing' },
      { value: 'IAB17-10', text: 'Figure Skating' },
      { value: 'IAB17-11', text: 'Fly Fishing' },
      { value: 'IAB17-16', text: 'Horse Racing' },
      { value: 'IAB17-17', text: 'Horses' },
      { value: 'IAB17-14', text: 'Game & Fish' },
      { value: 'IAB17-15', text: 'Golf' },
      { value: 'IAB17-18', text: 'Hunting/Shooting' },
      { value: 'IAB17-19', text: 'Inline Skating' },
    ],
    IAB18: [
      { value: 'IAB18', text: 'Style & Fashion' },
      { value: 'IAB18-6', text: 'Accessories' },
      { value: 'IAB18-5', text: 'Clothing' },
      { value: 'IAB18-4', text: 'Jewelry' },
      { value: 'IAB18-3', text: 'Fashion' },
      { value: 'IAB18-2', text: 'Body Art' },
      { value: 'IAB18-1', text: 'Beauty' },
    ],
    IAB19: [
      { value: 'IAB19-8', text: 'Computer Networking' },
      { value: 'IAB19', text: 'Technology & Computing' },
      { value: 'IAB19-34', text: 'Web Design/HTML' },
      { value: 'IAB19-35', text: 'Web Search' },
      { value: 'IAB19-2', text: 'Animation' },
      { value: 'IAB19-3', text: 'Antivirus Software' },
      { value: 'IAB19-1', text: '3-D Graphics' },
      { value: 'IAB19-6', text: 'Cell Phones' },
      { value: 'IAB19-7', text: 'Computer Certification' },
      { value: 'IAB19-4', text: 'C/C++' },
      { value: 'IAB19-5', text: 'Cameras & Camcorders' },
      { value: 'IAB19-25', text: 'Network Security' },
      { value: 'IAB19-24', text: 'Net for Beginners' },
      { value: 'IAB19-27', text: 'PC Support' },
      { value: 'IAB19-9', text: 'Computer Peripherals' },
      { value: 'IAB19-18', text: 'Internet Technology' },
      { value: 'IAB19-19', text: 'Java' },
      { value: 'IAB19-23', text: 'Net Conferencing' },
      { value: 'IAB19-22', text: 'MP3/MvalueI' },
      { value: 'IAB19-14', text: 'Desktop Vvalueeo' },
      { value: 'IAB19-15', text: 'Email' },
      { value: 'IAB19-16', text: 'Graphics Software' },
      { value: 'IAB19-17', text: 'Home Vvalueeo/DVD' },
      { value: 'IAB19-10', text: 'Computer Reviews' },
      { value: 'IAB19-11', text: 'Data Centers' },
      { value: 'IAB19-12', text: 'Databases' },
      { value: 'IAB19-13', text: 'Desktop Publishing' },
      { value: 'IAB19-32', text: 'Visual Basic' },
      { value: 'IAB19-33', text: 'Web Clip Art' },
      { value: 'IAB19-29', text: 'Entertainment' },
      { value: 'IAB19-30', text: 'Shareware/Freeware' },
      { value: 'IAB19-28', text: 'Portable' },
      { value: 'IAB19-31', text: 'Unix' },
      { value: 'IAB19-26', text: 'Palmtops/PDAs' },
      { value: 'IAB19-36', text: 'Windows' },
      { value: 'IAB19-21', text: 'Mac Support' },
      { value: 'IAB19-20', text: 'JavaScript' },
    ],
    IAB20: [
      { value: 'IAB20-21', text: 'Mexico & Central America' },
      { value: 'IAB20-20', text: 'Japan' },
      { value: 'IAB20-23', text: 'South America' },
      { value: 'IAB20-22', text: 'National Parks' },
      { value: 'IAB20-25', text: 'Theme Parks' },
      { value: 'IAB20-24', text: 'Spas' },
      { value: 'IAB20-27', text: 'United Kingdom' },
      { value: 'IAB20', text: 'Travel' },
      { value: 'IAB20-6', text: 'Budget Travel' },
      { value: 'IAB20-7', text: 'Business Travel' },
      { value: 'IAB20-4', text: 'Australia & New Zealand' },
      { value: 'IAB20-5', text: 'Bed & Breakfasts' },
      { value: 'IAB20-2', text: 'Africa' },
      { value: 'IAB20-3', text: 'Air Travel' },
      { value: 'IAB20-1', text: 'Adventure Travel' },
      { value: 'IAB20-26', text: 'Traveling with Kvalues' },
      { value: 'IAB20-8', text: 'By US Locale' },
      { value: 'IAB20-9', text: 'Camping' },
      { value: 'IAB20-18', text: 'Hotels' },
      { value: 'IAB20-19', text: 'Italy' },
      { value: 'IAB20-10', text: 'Canada' },
      { value: 'IAB20-11', text: 'Caribbean' },
      { value: 'IAB20-12', text: 'Cruises' },
      { value: 'IAB20-13', text: 'Eastern Europe' },
      { value: 'IAB20-14', text: 'Europe' },
      { value: 'IAB20-15', text: 'France' },
      { value: 'IAB20-16', text: 'Greece' },
      { value: 'IAB20-17', text: 'Honeymoons/Getaways' },
    ],
    IAB21: [
      { value: 'IAB21-3', text: 'Buying/Selling Homes' },
      { value: 'IAB21-2', text: 'Architects' },
      { value: 'IAB21-1', text: 'Apartments' },
      { value: 'IAB21', text: 'Real Estate' },
    ],
    IAB22: [
      { value: 'IAB22-1', text: 'Contests & Freebies' },
      { value: 'IAB22-2', text: 'Couponing' },
      { value: 'IAB22-3', text: 'Comparison' },
      { value: 'IAB22-4', text: 'Engines' },
      { value: 'IAB22', text: 'Shopping' },
    ],
    IAB23: [
      { value: 'IAB23-9', text: 'Latter-Day Saints' },
      { value: 'IAB23-8', text: 'Judaism' },
      { value: 'IAB23-5', text: 'Christianity' },
      { value: 'IAB23-4', text: 'Catholicism' },
      { value: 'IAB23-7', text: 'Islam' },
      { value: 'IAB23-6', text: 'Hinduism' },
      { value: 'IAB23-1', text: 'Alternative Religions' },
      { value: 'IAB23-3', text: 'Buddhism' },
      { value: 'IAB23-2', text: 'Atheism/Agnosticism' },
      { value: 'IAB23-10', text: 'Pagan/Wiccan' },
      { value: 'IAB23', text: 'Religion & Spirituality' },
    ],
    IAB24: [
      { value: 'IAB24', text: 'Uncategorized' },
    ],
    IAB25: [
      { value: 'IAB25', text: 'Non-Standard Content' },
      { value: 'IAB25-7', text: 'Incentivized' },
      { value: 'IAB25-6', text: 'Under Construction' },
      { value: 'IAB25-5', text: 'Hate Content' },
      { value: 'IAB25-4', text: 'Profane Content' },
      { value: 'IAB25-3', text: 'Pornography' },
      { value: 'IAB25-2', text: 'Extreme Graphic/Explicit Violence' },
      { value: 'IAB25-1', text: 'Unmoderated UGC' },
    ],
    IAB26: [
      { value: 'IAB26-4', text: 'Copyright Infringement' },
      { value: 'IAB26', text: 'Illegal Content' },
      { value: 'IAB26-1', text: 'Illegal Content' },
      { value: 'IAB26-2', text: 'Warez' },
      { value: 'IAB26-3', text: 'Spyware/Malware' },
    ],
  },
  currencyList: [
    { text: 'BYN', value: 'BYN' },
    { text: 'USD', value: 'USD' },
    { text: 'UAH', value: 'UAH' },
    { text: 'RUB', value: 'RUB' },
    { text: 'EUR', value: 'EUR' },
    { text: 'UZS', value: 'UZS' },
    { text: 'ILS', value: 'ILS' },
    { text: 'INR', value: 'INR' },
    { text: 'THB', value: 'THB' },
    { text: 'AED', value: 'AED' },
    { text: 'AFN', value: 'AFN' },
    { text: 'ALL', value: 'ALL' },
    { text: 'AMD', value: 'AMD' },
    { text: 'ANG', value: 'ANG' },
    { text: 'AOA', value: 'AOA' },
    { text: 'ARS', value: 'ARS' },
    { text: 'AUD', value: 'AUD' },
    { text: 'AWG', value: 'AWG' },
    { text: 'AZN', value: 'AZN' },
    { text: 'BAM', value: 'BAM' },
    { text: 'BBD', value: 'BBD' },
    { text: 'BDT', value: 'BDT' },
    { text: 'BGN', value: 'BGN' },
    { text: 'BHD', value: 'BHD' },
    { text: 'BIF', value: 'BIF' },
    { text: 'BMD', value: 'BMD' },
    { text: 'BND', value: 'BND' },
    { text: 'BOB', value: 'BOB' },
    { text: 'BRL', value: 'BRL' },
    { text: 'BSD', value: 'BSD' },
    { text: 'BTN', value: 'BTN' },
    { text: 'BWP', value: 'BWP' },
    { text: 'BZD', value: 'BZD' },
    { text: 'CAD', value: 'CAD' },
    { text: 'CDF', value: 'CDF' },
    { text: 'CHF', value: 'CHF' },
    { text: 'CLP', value: 'CLP' },
    { text: 'CNY', value: 'CNY' },
    { text: 'COP', value: 'COP' },
    { text: 'CRC', value: 'CRC' },
    { text: 'CUP', value: 'CUP' },
    { text: 'CVE', value: 'CVE' },
    { text: 'CZK', value: 'CZK' },
    { text: 'DJF', value: 'DJF' },
    { text: 'DKK', value: 'DKK' },
    { text: 'DOP', value: 'DOP' },
    { text: 'DZD', value: 'DZD' },
    { text: 'EGP', value: 'EGP' },
    { text: 'ERN', value: 'ERN' },
    { text: 'ETB', value: 'ETB' },
    { text: 'FJD', value: 'FJD' },
    { text: 'FKP', value: 'FKP' },
    { text: 'FOK', value: 'FOK' },
    { text: 'GBP', value: 'GBP' },
    { text: 'GEL', value: 'GEL' },
    { text: 'GGP', value: 'GGP' },
    { text: 'GHS', value: 'GHS' },
    { text: 'GIP', value: 'GIP' },
    { text: 'GMD', value: 'GMD' },
    { text: 'GNF', value: 'GNF' },
    { text: 'GTQ', value: 'GTQ' },
    { text: 'GYD', value: 'GYD' },
    { text: 'HKD', value: 'HKD' },
    { text: 'HNL', value: 'HNL' },
    { text: 'HRK', value: 'HRK' },
    { text: 'HTG', value: 'HTG' },
    { text: 'HUF', value: 'HUF' },
    { text: 'IDR', value: 'IDR' },
    { text: 'IMP', value: 'IMP' },
    { text: 'IQD', value: 'IQD' },
    { text: 'IRR', value: 'IRR' },
    { text: 'ISK', value: 'ISK' },
    { text: 'JEP', value: 'JEP' },
    { text: 'JMD', value: 'JMD' },
    { text: 'JOD', value: 'JOD' },
    { text: 'JPY', value: 'JPY' },
    { text: 'KES', value: 'KES' },
    { text: 'KGS', value: 'KGS' },
    { text: 'KHR', value: 'KHR' },
    { text: 'KID', value: 'KID' },
    { text: 'KMF', value: 'KMF' },
    { text: 'KRW', value: 'KRW' },
    { text: 'KWD', value: 'KWD' },
    { text: 'KYD', value: 'KYD' },
    { text: 'KZT', value: 'KZT' },
    { text: 'LAK', value: 'LAK' },
    { text: 'LBP', value: 'LBP' },
    { text: 'LKR', value: 'LKR' },
    { text: 'LRD', value: 'LRD' },
    { text: 'LSL', value: 'LSL' },
    { text: 'LYD', value: 'LYD' },
    { text: 'MAD', value: 'MAD' },
    { text: 'MDL', value: 'MDL' },
    { text: 'MGA', value: 'MGA' },
    { text: 'MKD', value: 'MKD' },
    { text: 'MMK', value: 'MMK' },
    { text: 'MNT', value: 'MNT' },
    { text: 'MOP', value: 'MOP' },
    { text: 'MRU', value: 'MRU' },
    { text: 'MUR', value: 'MUR' },
    { text: 'MVR', value: 'MVR' },
    { text: 'MWK', value: 'MWK' },
    { text: 'MXN', value: 'MXN' },
    { text: 'MYR', value: 'MYR' },
    { text: 'MZN', value: 'MZN' },
    { text: 'NAD', value: 'NAD' },
    { text: 'NGN', value: 'NGN' },
    { text: 'NIO', value: 'NIO' },
    { text: 'NOK', value: 'NOK' },
    { text: 'NPR', value: 'NPR' },
    { text: 'NZD', value: 'NZD' },
    { text: 'OMR', value: 'OMR' },
    { text: 'PAB', value: 'PAB' },
    { text: 'PEN', value: 'PEN' },
    { text: 'PGK', value: 'PGK' },
    { text: 'PHP', value: 'PHP' },
    { text: 'PKR', value: 'PKR' },
    { text: 'PLN', value: 'PLN' },
    { text: 'PYG', value: 'PYG' },
    { text: 'QAR', value: 'QAR' },
    { text: 'RON', value: 'RON' },
    { text: 'RSD', value: 'RSD' },
    { text: 'RWF', value: 'RWF' },
    { text: 'SAR', value: 'SAR' },
    { text: 'SBD', value: 'SBD' },
    { text: 'SCR', value: 'SCR' },
    { text: 'SDG', value: 'SDG' },
    { text: 'SEK', value: 'SEK' },
    { text: 'SGD', value: 'SGD' },
    { text: 'SHP', value: 'SHP' },
    { text: 'SLL', value: 'SLL' },
    { text: 'SOS', value: 'SOS' },
    { text: 'SRD', value: 'SRD' },
    { text: 'SSP', value: 'SSP' },
    { text: 'STN', value: 'STN' },
    { text: 'SYP', value: 'SYP' },
    { text: 'SZL', value: 'SZL' },
    { text: 'TJS', value: 'TJS' },
    { text: 'TMT', value: 'TMT' },
    { text: 'TND', value: 'TND' },
    { text: 'TOP', value: 'TOP' },
    { text: 'TRY', value: 'TRY' },
    { text: 'TTD', value: 'TTD' },
    { text: 'TVD', value: 'TVD' },
    { text: 'TWD', value: 'TWD' },
    { text: 'TZS', value: 'TZS' },
    { text: 'UGX', value: 'UGX' },
    { text: 'UYU', value: 'UYU' },
    { text: 'VES', value: 'VES' },
    { text: 'VND', value: 'VND' },
    { text: 'VUV', value: 'VUV' },
    { text: 'WST', value: 'WST' },
    { text: 'XAF', value: 'XAF' },
    { text: 'XCD', value: 'XCD' },
    { text: 'XDR', value: 'XDR' },
    { text: 'XOF', value: 'XOF' },
    { text: 'XPF', value: 'XPF' },
    { text: 'YER', value: 'YER' },
    { text: 'ZAR', value: 'ZAR' },
    { text: 'ZMW', value: 'ZMW' },
    { text: 'ZWL', value: 'ZWL' },
  ],
}

export default options
